import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { Router, Link, Location } from '@reach/router'
import posed from 'react-pose'
import SolidButton from '../components/buttons/SolidButton'
import Helmet from 'react-helmet'
import logosml from '../images/logo-white.svg'

export const PageTemplate = ({ title, content, backgroundImage, acf }) => {
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>
      <section
        className="row introduction"
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            backgroundImage && `url(${backgroundImage.source_url})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="breathe container container-960">
          <div className="row">
            <div className="col-sm-4 chapter-logo">
              <Link to="/">
                <img src={logosml} style={{ color: '#fff' }} />
              </Link>
            </div>
            <div className="col-sm-8 chapter-title">
              The State of AI 2019: Divergence
            </div>
          </div>
        </div>
        <div className="container container-960">
          <div className="row">
            <div className="col-md-12">
              <h1 className="breathe-btm">{title}</h1>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
        <div className="container container-960">
          <div className="row">
            <div className="col-md-12 text-center">
              {acf &&
                acf.nav_buttons_page &&
                acf.nav_buttons_page.map((buttons, i) => {
                  if (buttons.__typename === 'WordPressAcf_next_button') {
                    return (
                      <React.Fragment key={i}>
                        <span className="hidden-xs hidden-sm">
                          <SolidButton
                            url={buttons.next_button_link.post_name}
                            arrow="right"
                            label={buttons.next_button_text}
                            order={1000000000}
                          />
                        </span>
                        <span className="hidden-md hidden-lg">
                          <SolidButton
                            url={buttons.next_button_link.post_name}
                            arrow="right"
                            label="Next"
                            order={1000000000}
                          />
                        </span>
                      </React.Fragment>
                    )
                  }
                })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data, location }) => {
  const { wordpressPage: page } = data

  return (
    <PageTemplate
      backgroundImage={page.featured_media}
      title={page.title}
      content={page.content}
      acf={page.acf}
    />
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      id
      content
      featured_media {
        id
        source_url
      }
      slug
      acf {
        nav_buttons_page {
          __typename
          ... on WordPressAcf_next_button {
            next_button_text
            next_button_link {
              post_name
            }
          }
          __typename
          ... on WordPressAcf_previous_button {
            previous_button_text
            previous_button_link {
              post_name
            }
          }
        }
      }
    }
    allWordpressWpApiMenusMenusItems(filter: { name: { eq: "test" } }) {
      edges {
        node {
          count
          items {
            order
            title
            object_slug
            wordpress_children {
              wordpress_id
              order
              title
              object_slug
            }
          }
        }
      }
    }
    allWordpressPage(sort: { fields: [menu_order] }) {
      edges {
        node {
          menu_order
          slug
          title
        }
      }
    }
  }
`
